import React from "react";
import "./LayoutCentered.scss";
import logoTwWhite from "../assets/img/logo-tw-white.svg";
import logoSmart from "../assets/img/logo-smart.svg";
import {Link} from "react-router-dom";

export default function LayoutCentered({children, ...props}: any) {

    return (
        <div className={props.large ? "wrapperCentered large" : "wrapperCentered"}>
            <div className="container">
                <Link to={"/"}>
                    <div className="logoContainer">
                        <img
                            src={logoTwWhite}
                            alt="Logo"
                            className="logo"
                        />
                    </div>
                </Link>
                {children}
                <div className="text-center mt-5 p-2">
                    <img
                        src={logoSmart}
                        alt="Logo"
                        className="logo logoFooter"
                    />
                </div>
            </div>
        </div>
    );
};
