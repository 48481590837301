export interface AuthData {
  token: string;
  expirationUtc: number;
  refreshToken: string;
  refreshTokenExpirationUtc: number;
  mfaEnabled?: boolean;
  mfaInfo?: any;
}

export function AuthDataDeserializer(input: any): AuthData {
  return {
    token: input.token || "",
    expirationUtc: input.expirationUtc || "",
    refreshToken: input.refreshToken || "",
    refreshTokenExpirationUtc: input.refreshTokenExpirationUtc || "",
    mfaEnabled: input.mfaEnabled || false,
    mfaInfo: input.mfaInfo || undefined,
  };
}
