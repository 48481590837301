import qs from "query-string";
import api from "helpers/apiService";
import {AuthData, AuthDataDeserializer} from "../../../models/authData.interface";

export function smartSignin(): string {
    let params = {
        response_type: process.env.REACT_APP_SMART_OAUTH_RESPONSE_TYPE,
        client_id: process.env.REACT_APP_SMART_OAUTH_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_SMART_OAUTH_REDIRECT_URI,
        scope: process.env.REACT_APP_SMART_OAUTH_SCOPE,
        state: process.env.REACT_APP_SMART_OAUTH_STATE
    };

    const data = qs.stringify(params);

    return process.env.REACT_APP_SMART_OAUTH_BASE_URL + "/oauth/auth?" + data;
}

export async function oauthGetToken(code: string): Promise<AuthData> {
    const requestOptions = {
        headers: { "Content-Type": "application/json" },
    };

    let params = {
        grantType: "oauth2",
        oauthProviderType: 2,
        authCode: code,
        callbackUrl: process.env.REACT_APP_SMART_OAUTH_REDIRECT_URI
    };

    const resp = await api().post(`/identity/v1/auth/token`, params, requestOptions);
    return AuthDataDeserializer(resp.data);
}

export const authenticationService = {
    smartSignin,
    oauthGetToken
};
