import api from "helpers/apiService";
import { Room, RoomDeserializer } from "models/room.interface";
import { RoomInfo, NewRoom } from "modules/room/interfaces";
import { User, UserDeserializer } from "models/user.interface";

export const roomService = {
  getRooms,
  getRoom,
  getSingleRoom,
  createRoom,
  leaveRoom,
  getRoomInfo,
  removeUser,
  addUser,
  editRoom,
  searchUsers,
};

async function getRooms(max: number = 100): Promise<Room[]> {
  const resp = await api("chat").get(`api/GlobalChat/GetRooms`);
  return resp.data.map((rawRoom: any) => {
    return RoomDeserializer(rawRoom);
  });
}

async function getRoom(id: string): Promise<Room> {
  const resp = await api("chat").get(`api/Groups/${id}`);
  return RoomDeserializer(resp.data);
}

//HACK: fetch all rooms and keep only the single selected
async function getSingleRoom(id: string): Promise<Room> {
  const resp = await api("chat").get(`api/GlobalChat/GetRooms`);
  return RoomDeserializer(
    resp.data.find((room: any) => {
      return room.DestinationId === id || room.GroupId === id;
    })
  );
}

async function createRoom(room: NewRoom): Promise<Room> {
  const resp = await api("chat").post(`api/Groups`, room);
  return resp.data as Room;
}

async function getRoomInfo(
  id: string,
  isGroup: boolean = true
): Promise<RoomInfo> {
  const resp = await api("chat").get(
    `api/GlobalChat/GetChatMeeting/${id}?isGroup=${isGroup}`
  );
  return resp.data as RoomInfo;
}

async function removeUser(groupId: string, userId: string): Promise<boolean> {
  const resp = await api("chat").post(
    `api/Groups/RemoveUser/${groupId}/${userId}`,
    {}
  );
  return resp.data;
}

async function addUser(groupId: string, user: User): Promise<boolean> {
  const data = {
    GroupId: groupId,
    UserId: user.userId,
    IsAdmin: false,
  };
  const resp = await api("chat").post(`api/Groups/AddUser`, data);
  return resp.data;
}

async function leaveRoom(groupId: string): Promise<boolean> {
  const resp = await api("chat").post(`api/Groups/Leave/${groupId}`, {});
  return resp.data;
}

async function editRoom(groupId: string, name: string, description: string): Promise<Room> {
  const resp = await api("chat").put(
    `api/Groups`,
    {
      "GroupId": groupId,
      "Name": name,
      "Description": description,
    }
  );
  return RoomDeserializer(resp.data);
  // return resp.data;
}

async function searchUsers(filter: string): Promise<User[]> {
  const resp = await api("chat").get(
    `/api/GlobalChat/FindUsers?filter=${filter}`
  );
  return resp.data.map((rawUser: any) => {
    return UserDeserializer(rawUser);
  });
}
