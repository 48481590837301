import React, {useEffect, useState} from "react";
import {IRoute} from "router/config";
import {unwrapResult} from '@reduxjs/toolkit'
import {useSelector, useDispatch} from 'react-redux';
import {Link, useHistory} from "react-router-dom";
import {Alert, Form, Input, Button, Card, Tabs, Divider} from 'antd';
import {LoginOutlined} from "@ant-design/icons";
import LayoutAuth from "layouts/LayoutAuth";
import {FiMail, FiLock} from "react-icons/fi";
import "./Signin.scss";
import logoShort from "assets/img/logo-tw-white.png";

import {RootState} from "common/store";
import {smartSignin} from "../../services";
import LayoutCentered from "../../../../layouts/LayoutCentered";

const {TabPane} = Tabs;

interface IProps {
    routes: IRoute[];
}

const Signin: React.FC<IProps> = () => {
    const signinStatus = useSelector((state: RootState) => state.authentication.status)
    // const error = useSelector((state: RootState) => state.authentication.error)
    const [signinError, setSigninError] = useState("");
    const [activeTab] = useState("1");
    const dispatch = useDispatch()
    const history = useHistory();

    const onSubmitSignin = async (values: any) => {
        try {
            window.location.href = smartSignin()
        } catch (e) {
            setSigninError(e.message);
        }
    };

    const onSubmitSigninFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <LayoutCentered>
            <div className="auth-signin">
                <Card bordered={false}>
                    <h3 className="text-center mt-5 mb-2">Welcome to SMART TeamWorks Cloud admin panel</h3>
                    <p className="text-center mb-5">Please sign in with your SMART Account</p>
                    <Form
                        layout="vertical"
                        size="large"
                        name="signin"
                        onFinish={onSubmitSignin}
                        onFinishFailed={onSubmitSigninFailed}
                    >
                        <Form.Item
                            className="text-center mb-0">
                            <Button
                                loading={signinStatus === 'loading'}
                                type="primary"
                                htmlType="submit"
                                size="large"
                                className="mb-3"
                                icon={<LoginOutlined/>}
                            >
                                Sign in
                            </Button>
                        </Form.Item>
                        {signinError &&
                        <Alert message={signinError} type="error" showIcon/>
                        }
                    </Form>
                </Card>
            </div>
        </LayoutCentered>
    );
};

export default Signin;
