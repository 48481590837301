import servicesHelper from "helpers/services";
import api from "helpers/apiService";

export const sessionService = {
  connect,
  getGuestMeeting,
};

async function connect(
  values: { meetingId: string; pin: string; name?: string } | null = null,
  getState: any
) {
  const { headers, uniqueId } = servicesHelper.authHeaderRms();
  const requestOptions = {
    headers: headers,
  };

  const activeRoomInfo = getState().room?.activeRoomInfo;

  const data = {
    MeetingId: values ? values.meetingId : activeRoomInfo.ShortId,
    MeetingPin: values ? values.pin : activeRoomInfo.ModeratorPin || activeRoomInfo.Pin,
    Nickname: values?.name,
    UniqueId: uniqueId,
    AppPlatform: "Web",
    AppVersion: "1.0",
    ApiVersion: "3.3",
  };

  //LOGGED user data
  const me = getState().user?.me;
  if (me) {
    const loggedName = me.firstName + " " + me.lastName;
    data.Nickname = values?.name ? values.name : loggedName;
    data["UserId"] = me.userId;
  }

  const resp = await api().post(`api/auth`, data, requestOptions);
  return resp.data;
}

async function getGuestMeeting(getState: any) {
  const requestOptions = {
    headers: servicesHelper.guestAuthHeader(getState),
  };
  const resp = await api("meeting").get(
    `api/Meeting/GetMeeting`,
    requestOptions
  );
  return resp.data;
}
