import React, {useEffect, useState} from "react";
import debounce from 'lodash/debounce';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from "react-router-dom";
import {
    Row,
    Col,
    Divider,
    Avatar,
    Button,
    Spin,
    Input,
    AutoComplete,
    Badge,
    Table,
    Menu,
    Dropdown,
    Modal,
    Form, Popconfirm, message, Tag, Select
} from 'antd';
import {
    LoadingOutlined,
    TeamOutlined,
    DeleteOutlined,
    LeftOutlined, UserOutlined, MoreOutlined, ReloadOutlined, UserAddOutlined, PlusOutlined,
} from "@ant-design/icons/lib";
import {FiChevronLeft, FiLock, FiMail} from "react-icons/fi";

import {IRoute} from "router/config";
import LayoutCentered from "layouts/LayoutCentered";
import servicesHelper from "helpers/services";
import {RootState} from "common/store";
import {deleteUser, getLicenses, getUsers, postLicense, postUser} from "modules/license/redux/licenseSlice";
import {License, NewUser} from "modules/license/interfaces";
import {User, UserDeserializer} from "models/user.interface";
import "./UserList.scss";
import moment from "moment";
import {unwrapResult} from "@reduxjs/toolkit";

interface IProps {
    routes: IRoute[];
}

const {Option} = Select;

const tableLoading = {
    spinning: true,
    indicator: <LoadingOutlined/>,
};

const UserList: React.FC<IProps> = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const [userForm] = Form.useForm();
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const [selectedLicenseKey, setSelectedLicenseKey] = useState<string>("");
    const licenses: License[] = useSelector((state: RootState) => state.license.licenses)
    const currentUserId: string = useSelector((state: RootState) => state.user.me.userId)
    const users: User[] = useSelector((state: RootState) => state.license.users)
    const getUsersStatus = useSelector((state: RootState) => state.license.getUsersStatus)
    const deleteUserStatus = useSelector((state: RootState) => state.license.deleteUserStatus)
    const postUserStatus: string = useSelector((state: RootState) => state.license.postUserStatus)
    const checkPasswordStrength = (rule: any, value: any, callback: any) => {
        if (!value) {
            callback("Please insert a password");
            return;
        }
        if (value.length < 8) {
            callback("Password must contain at least 8 characters");
            return;
        }
        let re = /[0-9]/;
        if (!re.test(value)) {
            callback("Password must contain at least one number (0-9)");
            return;
        }
        re = /[a-z]/;
        if (!re.test(value)) {
            callback("Password must contain at least one lowercase letter (a-z)");
            return;
        }
        re = /[A-Z]/;
        if (!re.test(value)) {
            callback("Password must contain at least one uppercase letter (A-Z)");
            return;
        }
        re = /[^a-zA-Z0-9\s]/;
        if (!re.test(value)) {
            callback("Password must contain at least one special character");
            return;
        }
        callback();
        return;
    };

    const columns = [
        {
            title: 'Name / Email',
            dataIndex: 'FirstName',
            sorter: (a: User, b: User) => a.firstName.localeCompare(b.firstName),
            render: (text: any, row: any, idx: any) => <div>
                <Row>
                    <Col>
                        <h4 className="mb-0"><b>{row.firstName} {row.lastName}</b></h4>
                        <h5>{row.email}</h5>
                    </Col>
                </Row>
            </div>,
        },
        {
            title: 'License Key',
            dataIndex: 'LicenseKey',
            // @ts-ignore
            sorter: (a: User, b: User) => a.license?.Key.localeCompare(b.license?.Key),
            render: (text: any, row: any, idx: any) => <div>
                <Row>
                    <Col>
                        <h5>{row.license?.Key}</h5>
                    </Col>
                </Row>
            </div>,
        },
        {
            title: '',
            dataIndex: 'LicenseKey',
            render: (text: any, row: any, idx: any) => <div className="text-center" style={{zIndex: 9999}}>
                {currentUserId !== row.userId ? <Popconfirm
                    title="Are you sure? This action cannot be undone."
                    onConfirm={() => removeUser(row.userId)}
                    okText="Yes, delete"
                    cancelText="No"
                >
                    <Button loading={deleteUserStatus === row.userId} danger icon={<DeleteOutlined/>}>
                    </Button>
                </Popconfirm> : <Tag>Administrator</Tag>}
            </div>,
        },
    ];

    async function removeUser(userId: string) {
        try {
            const resultAction: any = await dispatch(
                deleteUser(userId)
            );
            unwrapResult(resultAction);
            message.success("User deleted");
            dispatch(getUsers());
        } catch (e) {
            message.error(e.message);
        }
    }

    async function createUser(values: any) {
        const newUser = {
            ...values,
            Username: values.Email,
            VisibleForSearch: false
        } as NewUser;
        try {
            const resultAction: any = await dispatch(
                postUser(newUser)
            );
            unwrapResult(resultAction);
            message.success("New User added!");
            setShowNewUserModal(false);
            dispatch(getLicenses());
            dispatch(getUsers());
        } catch (e) {
            message.error(e.message);
        }
    }

    useEffect(() => {
        dispatch(getUsers());
        dispatch(getLicenses());
    }, [dispatch])

    return (
        <LayoutCentered>
            <div className="user-list">
                <div className="user-header">
                    <Row align="middle" className="mb-0">
                        <Col xs={6}>
                            <Link to={"/"}>
                                <Button icon={<LeftOutlined/>}
                                        type="link" className="inline-block">Home</Button>
                            </Link>
                        </Col>
                        <Col xs={12} className="text-center">
                            <h3 className="ml-2 mb-0 inline-block font-bold">Room accounts</h3>
                        </Col>
                        <Col xs={6} className="text-right">
                            {licenses.length ?
                            <Button icon={<PlusOutlined/>} onClick={() => setShowNewUserModal(true)}
                                    type="primary" ghost className="inline-block">New</Button> : ""}
                        </Col>
                    </Row>
                </div>
                <Table
                    className="bg-white"
                    columns={columns}
                    pagination={false}
                    dataSource={users}
                    loading={getUsersStatus === 'loading' ? tableLoading : false}
                    bordered
                    rowKey="UserId"
                    //showHeader={false}
                />
            </div>
            <Modal
                visible={showNewUserModal}
                onCancel={() => setShowNewUserModal(false)}
                footer={null}
            >
                <Form
                    id="detailsForm"
                    form={userForm}
                    layout="vertical"
                    size="large"
                    name="license"
                    className="px-4 pb-2"
                    onFinish={(values: any) => {
                        createUser(values);
                    }}
                >
                    <Divider><h6 className="uppercase mt-2 text-gray-600">New room account</h6></Divider>
                    <Form.Item
                        label="License Key"
                        name="LicenseKey"
                        rules={[{required: true, message: 'Required field'}]}>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Select a license...">
                            {licenses.map(license => {
                                return (
                                    <Option key={license.Key} value={license.Key || ""}>{license.Key}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Room display name"
                        name="FirstName"
                        rules={[{required: true, message: "Required field"}]}
                    >
                        <Input
                        />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="Email"
                        rules={[{type: "email", required: true, message: 'Required field'}]}>
                        <Input
                            size={'large'}
                            placeholder="Email"
                            disabled={postUserStatus === "loading"}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="Password"
                        //@ts-ignore
                        rules={[{required: true, type: "string", message: 'Required field'},
                            ({getFieldValue}) => ({
                                validator(rule, value, callback) {
                                    return checkPasswordStrength(rule, value, callback)
                                }
                            })]}>
                        <Input.Password
                            size={'large'}
                            placeholder="Password"
                            disabled={postUserStatus === "loading"}
                        />
                    </Form.Item>
                    <Form.Item className="text-center mt-5">
                        <Row>
                            <Col xs={24} className="text-right">
                                <Button
                                    loading={postUserStatus === 'loading'}
                                    size="large"
                                    htmlType="submit"
                                    type="primary">
                                    Create account
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
        </LayoutCentered>
    );
};

export default UserList;
