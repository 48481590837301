import React, {useEffect, useState} from "react";
import {IRoute} from "router/config";
import {unwrapResult} from '@reduxjs/toolkit'
import {useSelector, useDispatch} from 'react-redux';
import {Link, useHistory} from "react-router-dom";
import {Alert, Form, Input, Button, Card, Tabs, Divider, Spin} from 'antd';
import {LoadingOutlined, LoginOutlined} from "@ant-design/icons";
import LayoutAuth from "layouts/LayoutAuth";
import {FiMail, FiLock} from "react-icons/fi";
import "./SmartSigninCallback.scss";
import logoShort from "assets/img/logo-tw-white.png";
import { useQueryParam, StringParam } from "use-query-params"

import {
    signin
} from '../../redux/authenticationSlice';
import {RootState} from "common/store";
import * as queryString from "querystring";
import LayoutCentered from "../../../../layouts/LayoutCentered";

const {TabPane} = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface IProps {
    routes: IRoute[];
}

const Signin: React.FC<IProps> = () => {
    const signinStatus = useSelector((state: RootState) => state.authentication.status)
    // const error = useSelector((state: RootState) => state.authentication.error)
    const [signinError, setSigninError] = useState("");
    const [code, setCode] = useState(useQueryParam("code", StringParam)[0] || undefined)
    const dispatch = useDispatch()
    const history = useHistory();

    useEffect(() => {
        if (code) {
            onSubmitSignin(code).then(() => {
                history.push("/");
            });
        }
    }, [])

    const onSubmitSignin = async (code: string) => {
        try {
            const resultAction: any = await dispatch(
                signin(code)
            );
            unwrapResult(resultAction);
            history.push("/");
        } catch (e) {
            setSigninError(e.message);
        }
    };

    const onSubmitSigninFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <LayoutCentered>
            <div className="auth-signin">
                <Card bordered={false} bodyStyle={{textAlign: "center"}}>
                    <Spin indicator={antIcon} />
                    <p className="text-center my-4">Loading...</p>
                </Card>
            </div>
        </LayoutCentered>
    );
};

export default Signin;
