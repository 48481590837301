import React  from "react";
import { BrowserRouter, Route } from "react-router-dom";

import { routes } from "router/config";
import { QueryParamProvider } from 'use-query-params';
import AppRouter from "router/Router";
import './App.less';
import './App.scss';

function App() {
    return (
        <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
                <AppRouter routes={routes}/>
            </QueryParamProvider>
        </BrowserRouter>
    );
}

export default App;
