import { v4 as uuidv4 } from "uuid";
import hmacsha256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";

import { User } from "models/user.interface";
import { AuthHeader } from "models/authHeader.interface";

const BASEURL = process.env.REACT_APP_RMS_API_BASEURL;
const PRESHAREDKEY = process.env.REACT_APP_RMS_PRE_SHARED_KEY;

export default {
  guestAuthHeader,
  authHeaderRms,
  baseUrl,
  getChatServerUrl,
  getMeetingServerUrl,
  isAuthenticated,
  connectsAsGuest,
  hasGuestData,
  getAuthToken,
  getRoomSessionToken,
  getUserId,
  getUserAvatarSrc,
  getRoomStyleClass,
};

function baseUrl() {
  return BASEURL;
}

function getChatServerUrl(): string {
  return localStorage.getItem("chat-server") || "";
}

function getMeetingServerUrl(): string {
  return localStorage.getItem("meeting-server") || "";
}

function isAuthenticated(): boolean {
  return !!localStorage.getItem("x-auth-token") && parseInt(localStorage.getItem("x-auth-token-expires") || "") > Date.now();
}

function hasGuestData(): boolean {
  return (
    !!localStorage.getItem("guest-room-id") &&
    !!localStorage.getItem("guest-room-pin") &&
    !!localStorage.getItem("guest-name")
  );
}

function connectsAsGuest(): string {
  return localStorage.getItem("connects-as-guest") || "";
}

function getAuthToken(): string {
  return localStorage.getItem("x-auth-token") || "";
}

function getRoomSessionToken(): string {
  return localStorage.getItem("room-session-token") || "";
}

function getUserId(): string {
  return localStorage.getItem("userId") || "";
}

function guestAuthHeader(getState: any, type = "Bearer"): AuthHeader {
  let token =
    getState().authentication.guestToken ||
    localStorage.getItem("guest-x-auth-token");
  if (token) {
    return { Authorization: type + " " + token };
  }
  return {};
}

function authHeaderRms(
  type: string = "RMS"
): { headers: AuthHeader; uniqueId: string } {
  const requestUri = `${BASEURL}api/auth`;
  const requestHttpMethod = "POST";
  const timeStamp = Math.round(+new Date() / 1000);
  let nonce = uuidv4().toString().replace(/-/g, "");
  let uniqueId = uuidv4().toString().replace(/-/g, "");
  const data = `${nonce}\n${timeStamp}\n${requestHttpMethod}\n${requestUri}\n${uniqueId}`;
  const signatureBytes = Base64.stringify(hmacsha256(data, PRESHAREDKEY));
  return {
    headers: {
      Authorization: `${type} ${nonce}:${timeStamp}:${signatureBytes}`,
    },
    uniqueId,
  };
}

function getUserAvatarSrc(user: User, size: number = 250): string {
  if (user?.avatarDate) {
    return `${getChatServerUrl()}api/Users/GetUserAvatar/${
      user.userId
    }/${size}`;
  }
  return "";
}

type roomStyle = "classroom" | "conference" | "meeting";
function getRoomStyleClass(style: number | undefined): roomStyle {
  switch (style) {
    case 1:
      return "classroom";
    case 2:
      return "conference";
    case 0:
    default:
      return "meeting";
  }
}
