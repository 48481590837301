import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { IRoute } from './config';
import { RootState } from "common/store";
import {getCompany, getUserMe} from "modules/user/redux/userSlice";
import { initChatHubConnection, initMeetingHubConnection, stopChatHubConnection, stopMeetingHubConnection } from "modules/chat/redux/chatSlice";
import servicesHelper from "helpers/services";
import { disconnectUserFromRoom } from "modules/authentication/redux/authenticationSlice";

const RouteWithSubRoutes = (route: IRoute) => {
    const dispatch = useDispatch()

    const token = useSelector((state: RootState) => state.authentication.authData.token)
    const authenticatedUser = token && servicesHelper.isAuthenticated()
    const me = useSelector((state: RootState) => state.user.me)
    const hasGuestData = servicesHelper.hasGuestData()
    const getMeStatus = useSelector((state: RootState) => state.user.getMeStatus)
    const chatHubConnectionState = useSelector((state: RootState) => state.chat.chatHubConnectionState)
    const meetingHubConnectionState = useSelector((state: RootState) => state.chat.meetingHubConnectionState)
    const chatHubConnectionStatus = useSelector((state: RootState) => state.chat.status)
    const meetingHubConnectionStatus = useSelector((state: RootState) => state.chat.meetingStatus)
    const connectUserStatus = useSelector((state: RootState) => state.authentication.connectUserStatus);
    const connectGuestStatus = useSelector((state: RootState) => state.authentication.connectGuestStatus);
    let returnStatus = 'proceed'

    if (route.redirect) {
        returnStatus = 'redirect';
    } else {
        if (authenticatedUser && (getMeStatus === 'idle') && Object.keys(me).length === 0) {
            dispatch(getUserMe({}))
            dispatch(getCompany())
        }
        if (route.private) {
            if (!authenticatedUser) {
                returnStatus = 'redirectAuth';
            } else {
                if (chatHubConnectionState === 4 && chatHubConnectionStatus !== 'loading') {
                    //dispatch(initChatHubConnection());
                }
            }
            if (route.path === "/room" && (connectUserStatus !== 'idle' || connectGuestStatus !== 'idle')) {
                // CLEAN previous user connections
                dispatch(disconnectUserFromRoom());
                //dispatch(stopChatHubConnection());
                dispatch(stopMeetingHubConnection());
            }
            if (route.path === "/room/g/:id" && meetingHubConnectionStatus === "succeeded" && (connectUserStatus !== 'idle' || connectGuestStatus !== 'idle')) {
                // CLEAN previous user connections
                dispatch(stopMeetingHubConnection());
            }

        }
        if (route.session) {
            if (!hasGuestData) {
                if (authenticatedUser) {
                    returnStatus = 'proceed';
                } else {
                    returnStatus = 'redirectJoin';
                }
            }
            //INIT MEETING HUB CONNECTION
            if (connectUserStatus === 'succeeded' && meetingHubConnectionState === 4 && meetingHubConnectionStatus !== 'loading') {
                dispatch(initMeetingHubConnection());
            }
        }
    }

    switch (returnStatus) {
        case 'redirect': {
            return (
                <Suspense fallback={route.fallback}>
                    <Route
                        path={route.path}
                        render={() => <Redirect to={route.redirect} />}
                    />
                </Suspense>
            )
        }
        case 'redirectAuth': {
            return (
                <Suspense fallback={route.fallback}>
                    <Route
                        path={route.path}
                        render={() => <Redirect to='/auth/signin' />}
                    />
                </Suspense>
            )
        }
        case 'redirectJoin': {
            return (
                <Suspense fallback={route.fallback}>
                    <Route
                        path={route.path}
                        render={() => <Redirect to='/join' />}
                    />
                </Suspense>
            )
        }
        case 'proceed':
        default: {
            return (
                <Suspense fallback={route.fallback}>
                    <Route
                        path={route.path}
                        render={(props) => route.component &&
                            <route.component {...props} isGroup={route.isGroup} routes={route.routes} />
                        }
                    />
                </Suspense>
            )
        }
    }
};

export default RouteWithSubRoutes;
