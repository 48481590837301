import React, {ComponentType, ReactNode} from 'react';
import Signin from "modules/authentication/components/Signin/Signin";
import Home from "modules/room/components/Home/Home";
import LicenseList from "../modules/license/components/LicenseList/LicenseList";
import LicenseNew from "../modules/license/components/LicenseNew/LicenseNew";
import UserList from "../modules/license/components/UserList/UserList";
import SmartSigninCallback from "../modules/authentication/components/OAuth/SmartSigninCallback";

export interface IRoute {
    path: string;
    exact: boolean;
    fallback: NonNullable<ReactNode> | null;
    component?: ComponentType<any>;
    routes?: IRoute[];
    redirect?: string;
    private?: boolean; //VALAREA USER AUTHENTICATED ROUTE
    session?: boolean; //ROOM SESSION ROUTE
    isGroup?: boolean; //ROOM IS GROUP
}

export const routes: IRoute[] = [
    {
        path: '/auth/signin',
        exact: true,
        component: Signin,
        fallback: <div>Loading...</div>
    },
    {
        path: '/auth/oauth/redirect',
        exact: true,
        component: SmartSigninCallback,
        fallback: <div>Loading...</div>
    },
    {
        path: '/',
        exact: true,
        private: true,
        component: Home,
        fallback: <div>Loading...</div>
    },
    {
        path: '/license',
        component: LicenseList,
        exact: true,
        private: true,
        fallback: <div>Loading...</div>,
    },
    {
        path: '/license/new',
        component: LicenseNew,
        exact: true,
        private: true,
        fallback: <div> Loading... </div>
    },
    {
        path: '/user',
        component: UserList,
        exact: true,
        private: true,
        fallback: <div>Loading...</div>,
    }
];
