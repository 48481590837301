import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { userService } from '../services'
import {Company, User} from "models/user.interface";

const initialState = {
    me: {} as User,
    company: {} as Company,
    getMeStatus: 'idle',
    getCompanyStatus: 'idle',
    error: null,
}

export const getUserMe = createAsyncThunk(
    'user/getMe',
    async (args: any, { dispatch, getState }) => {
        const response = await userService.getUserMe(getState);
        return response;
    }
)
export const getCompany = createAsyncThunk(
    'user/getCompany',
    async () => {
        const response = await userService.getCompany();
        return response;
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset() {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder.addCase(getUserMe.pending, (state, action) => {
            state.getMeStatus = 'loading';
        })
        builder.addCase(getUserMe.fulfilled, (state, action) => {
            state.getMeStatus = 'succeeded';
            state.me = action.payload; // TODO type me
        })
        builder.addCase(getUserMe.rejected, (state, action) => {
            state.getMeStatus = 'failed';
            state.error = action.payload as any;
        })
        builder.addCase(getCompany.pending, (state, action) => {
            state.getCompanyStatus = 'loading';
        })
        builder.addCase(getCompany.fulfilled, (state, action) => {
            state.getCompanyStatus = 'succeeded';
            state.company = action.payload;
        })
        builder.addCase(getCompany.rejected, (state, action) => {
            state.getCompanyStatus = 'failed';
            state.error = action.payload as any;
        })
    },
})

export const { reset } = userSlice.actions

export default userSlice.reducer
