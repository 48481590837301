import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from "react-router-dom";
import {
    Row,
    Col,
    Divider,
    Avatar,
    Button,
    Input,
    Badge,
    Table,
    Menu,
    Dropdown,
    Modal,
    Form, Popconfirm, message
} from 'antd';
import {
    LoadingOutlined,
    TeamOutlined,
    DeleteOutlined,
    LeftOutlined, UserOutlined, MoreOutlined, UserAddOutlined, PlusOutlined,
} from "@ant-design/icons/lib";

import {IRoute} from "router/config";
import LayoutCentered from "layouts/LayoutCentered";
import {RootState} from "common/store";
import {deleteUser, getLicenses, postUser} from "modules/license/redux/licenseSlice";
import {License, NewUser} from "modules/license/interfaces";
import {User, UserDeserializer} from "models/user.interface";
import "./LicenseList.scss";
import moment from "moment";
import {unwrapResult} from "@reduxjs/toolkit";
import {BsDisplay} from "react-icons/bs";

interface IProps {
    routes: IRoute[];
}

const tableLoading = {
    spinning: true,
    indicator: <LoadingOutlined/>,
};

const LicenseList: React.FC<IProps> = () => {
    const dispatch = useDispatch()
    const [userForm] = Form.useForm();
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const [selectedLicenseKey, setSelectedLicenseKey] = useState<string>("");
    const licenses: License[] = useSelector((state: RootState) => state.license.licenses)
    const getLicensesState = useSelector((state: RootState) => state.license.getLicensesStatus)
    const deleteUserStatus = useSelector((state: RootState) => state.license.deleteUserStatus)
    const postUserStatus: string = useSelector((state: RootState) => state.license.postUserStatus)

    const columns = [
        {
            title: 'Key',
            dataIndex: 'Key',
            render: (text: any, row: any, idx: any) => {
                const status = moment(row.ExpirationDate).isAfter(moment()) ? "success" : "warning";
                if (!row.ParentKey) {
                    return <div>
                        <Row align="middle">
                            <Col>
                                <h4 className="mb-1"><Badge status={status}/> {text}</h4>
                                <h5 className="mb-0">{row.Product?.Name}</h5>
                            </Col>
                        </Row>
                    </div>
                } else {
                    return <div>
                        <Row align="middle">
                            <Col>
                                <h4 className="mb-0"><Badge status={status}/> {text}</h4>
                                <h5>This key has been used to renew the following key: <br/>{row.ParentKey}</h5>
                            </Col>
                        </Row>
                    </div>
                }
            },
        },
        {
            title: 'Expiration',
            dataIndex: 'ExpirationDate',
            render: (text: any, row: any, idx: any) => {
                if (!row.ParentKey) {
                    return <div>
                        <h5 className="mb-0">{moment(row.ExpirationDate).format('LL')}</h5>
                    </div>
                } else {
                    return ""
                }
            }
        },
        {
            title: 'Users',
            dataIndex: 'Key',
            render: (text: any, row: any, idx: any) => {
                if (!row.ParentKey) {
                    return <div className="text-right">
                        <h4 className="mb-0"><BsDisplay/><span
                            className="px-3">{row.Users.length} / {row.MaxUsers}</span></h4>
                    </div>
                }
                return ""
            }
        }
    ];

    async function removeUser(userId: string) {
        try {
            const resultAction: any = await dispatch(
                deleteUser(userId)
            );
            unwrapResult(resultAction);
            message.success("User deleted");
            dispatch(getLicenses());
        } catch (e) {
            message.error(e.message);
        }
    }

    useEffect(() => {
        dispatch(getLicenses());
    }, [dispatch])

    return (
        <LayoutCentered>
            <div className="license-list">
                <div className="license-header">
                    <Row align="middle" className="mb-0">
                        <Col xs={6}>
                            <Link to={"/"}>
                                <Button icon={<LeftOutlined/>}
                                        type="link" className="inline-block">Home</Button>
                            </Link>
                        </Col>
                        <Col xs={12} className="text-center">
                            <h3 className="ml-2 mb-0 inline-block font-bold">License Keys</h3>
                        </Col>
                        <Col xs={6} className="text-right">
                            <Link to={"/license/new"}>
                                <Button icon={<PlusOutlined/>}
                                        type="primary" ghost className="inline-block">Add</Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
                <Table
                    rowClassName="cursor-pointer"
                    className="bg-white"
                    columns={columns}
                    pagination={false}
                    dataSource={licenses}
                    loading={getLicensesState === 'loading' ? tableLoading : false}
                    bordered
                    rowKey="Key"
                    showHeader={false}
                    expandable={{
                        rowExpandable: record => !record.ParentKey,
                        expandRowByClick: true,
                        expandedRowRender: record => <p style={{margin: 0}}>{
                            record.Users.length ? record.Users.map((user: User, idx: number) => {
                                user = UserDeserializer(user);
                                return (
                                    <Row align="middle" className="my-0">
                                        <Col xs={20}>
                                            <Row align="middle">
                                                <Col>
                                                    <h4 className="mb-0">{user.firstName} {user.lastName}</h4>
                                                    <h5>{user.email}</h5>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={4} className="text-right">
                                            <Popconfirm
                                                title="Are you sure? This action cannot be undone."
                                                onConfirm={() => removeUser(user.userId)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button loading={deleteUserStatus === user.userId} danger
                                                        icon={<DeleteOutlined/>}>
                                                    Delete
                                                </Button>
                                            </Popconfirm>
                                        </Col>
                                        {idx + 1 !== record.Users.length && <Divider className="my-2"/>}
                                    </Row>
                                )
                            }) : "No Room accounts added yet."}</p>
                    }}
                />
            </div>
        </LayoutCentered>
    );
};

export default LicenseList;
