import { User, UserDeserializer } from "models/user.interface";

export interface Room {
  groupId: string;
  destinationId?: string;
  name: string;
  isGroup?: boolean;
  description?: string;
  groupStyle?: number;
  users?: User[];
  createdByUserId?: string;
  creationDate?: string;
  updateDate?: Date;
  avatarDate?: Date;
  groupInfo?: Room;
}

export function RoomDeserializer(input: any): Room {
  const users = input.Users?.map((rawUser: any) => {
    return rawUser ? UserDeserializer(rawUser) : undefined;
  });
  const groupInfo = input.GroupInfo
    ? RoomDeserializer(input.GroupInfo)
    : undefined;

  //HACK: caused by different api responses, if room has defined no "Isgroup" field but  has user is a group 
  let isGroup: boolean;
  if(input.IsGroup === false || groupInfo?.isGroup === false){
    isGroup = false;
  } else {
    isGroup = input.IsGroup || !groupInfo?.isGroup || input.Users  
  }
  
  return {
    groupId: input.GroupId || groupInfo?.groupId || "",
    destinationId: input.DestinationId || groupInfo?.destinationId,
    name: input.Name || groupInfo?.name || "",
    isGroup: isGroup,
    description: input.Description || groupInfo?.description || "",
    groupStyle: input.GroupStyle || groupInfo?.groupStyle,
    users: users || groupInfo?.users,
    createdByUserId: input.CreatedByUserId || groupInfo?.createdByUserId,
    creationDate: input.CreationDate || groupInfo?.creationDate,
    updateDate: input.UpdateDate || groupInfo?.updateDate,
    avatarDate: input.AvatarDate || groupInfo?.avatarDate,
    groupInfo: groupInfo,
  };
}

