import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import authenticationReducer from "../modules/authentication/redux/authenticationSlice";
import userReducer from "../modules/user/redux/userSlice";
import roomReducer from "../modules/room/redux/roomSlice";
import chatReducer from "../modules/chat/redux/chatSlice";
import licenseReducer from "../modules/license/redux/licenseSlice";

export const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        user: userReducer,
        room: roomReducer,
        chat: chatReducer,
        license: licenseReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
