import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ChatMessage,
  ChatMessageDeserializer,
} from "models/chatMessage.interface";
import { chatService } from "../services";

const initialState = {
  chatHubConnectionState: 4,
  meetingHubConnectionState: 4,
  status: "idle",
  meetingStatus: "idle",
  messages: [],
  logs: [],
  getRoomMessagesStatus: "idle",
  getRoomLogMessagesStatus: "idle",
  sendMessageStatus: "idle",
  sendLogMessageStatus: "idle",
  receiveMessageStatus: "idle",
  receiveLogMessageStatus: "idle",
  error: null,
};

export const initChatHubConnection = createAsyncThunk(
  "chat/initConnection",
  async (values, { dispatch, getState }) => {
    return await chatService.initChatHubConnection(dispatch, getState);
  }
);
export const initMeetingHubConnection = createAsyncThunk(
  "chat/initMeetingConnection",
  async (values, { dispatch, getState }) => {
    return await chatService.initMeetingHubConnection(dispatch, getState);
  }
);

export const stopChatHubConnection = createAsyncThunk(
  "chat/stopConnection",
  async () => {
    return await chatService.stopChatHubConnection();
  }
);
export const stopMeetingHubConnection = createAsyncThunk(
  "chat/stopMeetingConnection",
  async () => {
    return await chatService.stopMeetingHubConnection();
  }
);

export const getRoomMessages = createAsyncThunk(
  "chat/getRoomMessages",
  async (roomId: string, { dispatch, getState }) => {
    return await chatService.getMessages({ roomId });
  }
);
export const getRoomLogMessages = createAsyncThunk(
  "chat/getRoomLogMessages",
  async (roomId: string, { dispatch, getState }) => {
    return await chatService.getMessages({
      roomId,
      types: [110, 120, 130, 140, 150],
    });
  }
);

export const sendMessage = createAsyncThunk(
  "chat/sendMessage",
  async (
    message: {
      type: string;
      message: string;
      groupId: string | undefined;
      isGroup: boolean;
    },
    { dispatch, getState }
  ) => {
    return await chatService.send(message, getState);
  }
);

export const sendLogMessage = createAsyncThunk(
  "chat/sendLogMessage",
  async (
    message: {
      type: string;
      groupId: string;
    },
    { dispatch, getState }
  ) => {
    return await chatService.send(message, getState);
  }
);

export const setWebConferenceStatus = createAsyncThunk(
  "chat/sendLogMessage",
  async (
    args: {
      status: boolean;
    },
    { dispatch, getState }
  ) => {
    return await chatService.setWebConferenceStatus(args, getState);
  }
);

export const receiveMessage = createAsyncThunk(
  "chat/receiveMessage",
  async (message: ChatMessage, { dispatch, getState }) => {
    return await chatService.receive(message);
  }
);
export const receiveLogMessage = createAsyncThunk(
  "chat/receiveLogMessage",
  async (message: ChatMessage, { dispatch, getState }) => {
    return await chatService.receive(message);
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    connectionChanged(state, action) {
      state.chatHubConnectionState = action.payload;
    },
  },
  extraReducers: (builder) => {
    //INIT CONNECTION
    builder.addCase(initChatHubConnection.pending, (state, action) => {
      state.status = "loading";
      state.chatHubConnectionState = 0;
    });
    builder.addCase(initChatHubConnection.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.chatHubConnectionState = 1;
    });
    builder.addCase(initChatHubConnection.rejected, (state, action) => {
      state.status = "failed";
      state.chatHubConnectionState = 4;
      state.error = action.payload as any;
    });
    //INIT HUB CONNECTION
    builder.addCase(initMeetingHubConnection.pending, (state, action) => {
      state.meetingStatus = "loading";
      state.meetingHubConnectionState = 0;
    });
    builder.addCase(initMeetingHubConnection.fulfilled, (state, action) => {
      state.meetingStatus = "succeeded";
      state.meetingHubConnectionState = 1;
    });
    builder.addCase(initMeetingHubConnection.rejected, (state, action) => {
      state.meetingStatus = "failed";
      state.meetingHubConnectionState = 4;
      state.error = action.payload as any;
    });
    //STOP CONNECTION
    builder.addCase(stopChatHubConnection.pending, (state, action) => {
      state.status = "loading";
      state.chatHubConnectionState = 0;
    });
    builder.addCase(stopChatHubConnection.fulfilled, (state, action) => {
      state.status = "idle";
      state.chatHubConnectionState = 4;
    });
    builder.addCase(stopChatHubConnection.rejected, (state, action) => {
      state.status = "failed";
      state.chatHubConnectionState = 4;
      state.error = action.payload as any;
    });
    //STOP HUB CONNECTION
    builder.addCase(stopMeetingHubConnection.pending, (state, action) => {
      state.meetingStatus = "loading";
      state.meetingHubConnectionState = 0;
    });
    builder.addCase(stopMeetingHubConnection.fulfilled, (state, action) => {
      state.meetingStatus = "idle";
      state.meetingHubConnectionState = 4;
    });
    builder.addCase(stopMeetingHubConnection.rejected, (state, action) => {
      state.meetingStatus = "failed";
      state.meetingHubConnectionState = 4;
      state.error = action.payload as any;
    });
    //GET ROOM MESSAGES
    builder.addCase(getRoomMessages.pending, (state, action) => {
      state.getRoomMessagesStatus = "loading";
    });
    builder.addCase(getRoomMessages.fulfilled, (state, action) => {
      state.getRoomMessagesStatus = "succeeded";
      state.messages = { ...state.messages, [action.meta.arg]: action.payload };
    });
    builder.addCase(getRoomMessages.rejected, (state, action) => {
      state.getRoomMessagesStatus = "failed";
      state.error = action.payload as any;
    });
    //SEND MESSAGE
    builder.addCase(sendMessage.pending, (state, action) => {
      state.sendMessageStatus = "loading";
      // TODO add optimistic handling of chat message for better performance
    });
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      if (action.meta.arg.groupId) {
        state.sendMessageStatus = "succeeded";
        const payload = action.payload as any;
        const msg = ChatMessageDeserializer(payload.resp);
        state.messages[action.meta.arg.groupId].push(msg);
      }
    });
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.sendMessageStatus = "failed";
      state.error = action.payload as any;
    });
    //RECEIVE MESSAGE
    builder.addCase(receiveMessage.pending, (state, action) => {
      state.receiveMessageStatus = "loading";
    });
    builder.addCase(receiveMessage.fulfilled, (state, action) => {
      state.receiveMessageStatus = "succeeded";
      if (state.messages[action.meta.arg.destinationId]) {
        state.messages[action.meta.arg.destinationId].push(action.payload);
      }
    });
    builder.addCase(receiveMessage.rejected, (state, action) => {
      state.receiveMessageStatus = "failed";
      state.error = action.payload as any;
    });
    //GET LOG MESSAGES
    builder.addCase(getRoomLogMessages.pending, (state, action) => {
      state.getRoomLogMessagesStatus = "loading";
    });
    builder.addCase(getRoomLogMessages.fulfilled, (state, action) => {
      state.getRoomLogMessagesStatus = "succeeded";
      if (!state.logs[action.meta.arg]) {
        state.logs = { ...state.logs, [action.meta.arg]: action.payload };
      }
    });
    builder.addCase(getRoomLogMessages.rejected, (state, action) => {
      state.getRoomLogMessagesStatus = "failed";
      state.error = action.payload as any;
    });
    //SEND LOG MESSAGE
    builder.addCase(sendLogMessage.pending, (state, action) => {
      state.sendLogMessageStatus = "loading";
    });
    builder.addCase(sendLogMessage.fulfilled, (state, action) => {
      state.sendLogMessageStatus = "succeeded";
      //state.logs[action.meta.arg.groupId].push(action.payload.resp)
    });
    builder.addCase(sendLogMessage.rejected, (state, action) => {
      state.sendLogMessageStatus = "failed";
      state.error = action.payload as any;
    });
    //RECEIVE LOG MESSAGE
    builder.addCase(receiveLogMessage.pending, (state, action) => {
      state.receiveLogMessageStatus = "loading";
    });
    builder.addCase(receiveLogMessage.fulfilled, (state, action) => {
      state.receiveLogMessageStatus = "succeeded";
      if (state.logs[action.meta.arg.destinationId]) {
        state.logs[action.meta.arg.destinationId].push(action.payload);
      }
    });
    builder.addCase(receiveLogMessage.rejected, (state, action) => {
      state.receiveLogMessageStatus = "failed";
      state.error = action.payload as any;
    });
  },
});

export const { connectionChanged } = chatSlice.actions;

export default chatSlice.reducer;
