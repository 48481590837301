import { ChatMessageDeserializer } from "models/chatMessage.interface";
import servicesHelper from "helpers/services";
import { message } from "antd";
import {
  connectionChanged,
  receiveMessage,
  receiveLogMessage,
} from "modules/chat/redux/chatSlice";
import api from "helpers/apiService";
import * as signalr from "signalr-no-jquery";
import { activateGuestRoom } from "modules/authentication/redux/authenticationSlice";

type AlertType = "success" | "info" | "warning" | "error";

let chatHubConnection: any = null;
let meetingHubConnection: any = null;
let chatHubProxy: any = null;
let meetingHubProxy: any = null;

export const chatService = {
  chatHubConnection,
  meetingHubConnection,
  chatHubProxy,
  meetingHubProxy,
  initChatHubConnection,
  initMeetingHubConnection,
  stopChatHubConnection,
  stopMeetingHubConnection,
  getMessages,
  receive,
  send,
  sendMessage,
  setWebConferenceStatus,
  logMessagesDecoder,
};

async function initChatHubConnection(dispatch: any, getState: any) {
  return await initSignalRChat(getState, dispatch).then(() => {
    return connectChatSignalR(dispatch);
  });
}

async function initMeetingHubConnection(dispatch: any, getState: any) {
  return await initSignalRMeeting(getState, dispatch).then(() => {
    return connectMeetingSignalR(dispatch);
  });
}

async function stopChatHubConnection() {
  if (chatHubConnection != null) chatHubConnection.stop();
}

async function stopMeetingHubConnection() {
  if (meetingHubConnection != null) {
    if (meetingHubProxy) {
      return new Promise((resolve, reject) => {
        meetingHubProxy
          .invoke("SetWebConferenceStatus", false)
          .done((resp: any) => {
            meetingHubConnection.stop();
            resolve({ resp });
          })
          .fail(function (e: any) {
            console.error(`SetWebConferenceStatus fail  ${e}`);
            reject();
          });
      });
    }
  }
}

function initSignalRChat(getState: any, dispatch: any) {
  return new Promise((resolve, reject) => {
    if (chatHubConnection != null) chatHubConnection.stop();

    chatHubConnection = signalr.hubConnection(
      servicesHelper.getChatServerUrl()
    );
    chatHubConnection.logging = true;
    chatHubConnection.qs = "access_token=" + servicesHelper.getAuthToken();

    chatHubProxy = chatHubConnection.createHubProxy("ChatHub");

    chatHubProxy.on(
      "OnSendMessage",
      (
        rawMsg: any,
        userId: string,
        serverTimestamp: any //TODO: type me
      ) => {
        const chatMessageInfo = ChatMessageDeserializer(rawMsg);
        //CHAT MESSAGE
        if (chatMessageInfo.messageType === "Message") {
          dispatch(receiveMessage(chatMessageInfo));
        } else {
          const [title, type] = logMessagesDecoder(chatMessageInfo.messageType);
          if (
            getState().room.activeRoom &&
            chatMessageInfo.destinationId === getState().room.activeRoom.groupId
          ) {
            const content = `${chatMessageInfo.user.firstName} ${chatMessageInfo.user.lastName} ${title}`;
            switch (type) {
              case "success":
                message.success(content);
                break;
              case "warning":
                message.warning(content);
                break;
              case "info":
                message.info(content);
                break;
              default:
                break;
            }
          }
          dispatch(receiveLogMessage(chatMessageInfo));
        }
      }
    );

    resolve();
  }).catch((err) => {
    console.error(err);
  });
}

function initSignalRMeeting(getState: any, dispatch: any) {
  return new Promise((resolve, reject) => {
    if (meetingHubConnection != null) meetingHubConnection.stop();

    meetingHubConnection = signalr.hubConnection(
      servicesHelper.getMeetingServerUrl()
    );
    meetingHubConnection.logging = true;
    meetingHubConnection.qs = "auth=" + servicesHelper.getRoomSessionToken();

    meetingHubProxy = meetingHubConnection.createHubProxy("MeetingHub");

    // POSSIBLE EVENTS USED FOR DEBUG PURPOSES
    // meetingHubProxy.on("OnUserConnect", (user: any, serverTimestamp: any) => {debugger});
    // meetingHubProxy.on("OnUserDisconnect", (user: any, serverTimestamp: any) => {debugger});
    // meetingHubProxy.on("OnDisconnectUsersHub",(user: any, serverTimestamp: any) => {debugger});

    meetingHubProxy.on(
      "OnSetWebConferenceStatus",
      (arg: any, serverTimestamp: any) => {
        dispatch(activateGuestRoom(arg));
      }
    );

    resolve();
  }).catch((err) => {
    console.error(err);
  });
}

function logMessagesDecoder(messageType: string): [string, AlertType] {
  let title: string;
  let type: AlertType;
  switch (messageType) {
    case "OpenWebConference":
      title = "joined the video conference.";
      type = "success";
      break;
    case "OpenRoom":
      title = "entered the room.";
      type = "success";
      break;
    case "CloseWebConference":
      title = "left the video conference.";
      type = "info";
      break;
    case "CloseRoom":
      title = "left this room session.";
      type = "info";
      break;
    case "AddUserToGroup":
      title = "has been added as a member.";
      type = "info";
      break;
    case "RemoveUserFromGroup":
      title = "has been removed from members.";
      type = "warning";
      break;
    case "UserLeaveGroup":
      title = "has abandoned the room.";
      type = "warning";
      break;
    case "GroupCreateOrUpdated":
      title = "modified room info";
      type = "info";
      break;
    case "Info":
    default:
      title = "";
      type = "info";
      break;
  }
  return [title, type];
}

function connectChatSignalR(dispatch: any = null) {
  return new Promise((resolve, reject) => {
    let connectionRetry = 3;
    let currentRetry = 0; // Gestione retry max 3
    let retryTimeout = 2000; // Intervallo di tempo
    try {
      chatHubConnection.disconnected(function () {
        console.log("SignalR state disconnected ");
      });

      chatHubConnection.reconnected(function () {
        console.log("SignalR state reconnected ");
      });

      chatHubConnection.connectionSlow(function () {
        console.log("SignalR state connectionSlow ");
      });

      chatHubConnection.stateChanged(
        (state: { newState: string | number; oldState: string | number }) => {
          let stateConversion = {
            0: "connecting",
            1: "connected",
            2: "reconnecting",
            4: "disconnected",
          };
          dispatch(connectionChanged(state.newState));

          console.log(
            `SignalR state changed from: ${
              stateConversion[state.oldState]
            } to: ${stateConversion[state.newState]}`
          );
        }
      );

      chatHubConnection
        .start({ transport: ["webSockets", "longPolling"] })
        .done(function () {
          // Connessione effettuata
          console.log("Chat Hub Connection Done!");
          resolve();
        })
        .fail(function (e: any) {
          console.error(`tryConnectSignalR fail ${e}`);

          currentRetry++;
          if (currentRetry < connectionRetry)
            // Retry
            setTimeout(function () {
              connectChatSignalR().then(() => {
                resolve();
              });
            }, retryTimeout);
          else {
            console.error(
              `Cannot connect to the server at this time. Please try login again.`
            );
            reject();
          }
        });
    } catch (e) {
      console.error(`tryConnectSignalR error ${e}`);

      currentRetry++;
      if (currentRetry < connectionRetry)
        setTimeout(function () {
          connectChatSignalR().then(() => {
            resolve();
          });
        }, retryTimeout);
      else {
        console.error(
          `Cannot connect to the server at this time. Please try login again.`
        );
        reject();
      }
    }
  });
}

function connectMeetingSignalR(dispatch: any = null) {
  return new Promise((resolve, reject) => {
    let connectionRetry = 3;
    let currentRetry = 0; // Gestione retry max 3
    let retryTimeout = 2000; // Intervallo di tempo
    try {
      meetingHubConnection.disconnected(function () {
        console.log("SignalR MEETING state disconnected ");
      });

      meetingHubConnection.reconnected(function () {
        console.log("SignalR MEETING state reconnected ");
      });

      meetingHubConnection.connectionSlow(function () {
        console.log("SignalR MEETING state connectionSlow ");
      });

      meetingHubConnection.stateChanged(
        (state: { newState: string | number; oldState: string | number }) => {
          let stateConversion = {
            0: "connecting",
            1: "connected",
            2: "reconnecting",
            4: "disconnected",
          };
          // dispatch(connectionChanged(state.newState));

          console.log(
            `SignalR  MEETING state changed from: ${
              stateConversion[state.oldState]
            } to: ${stateConversion[state.newState]}`
          );
        }
      );

      meetingHubConnection
        .start({ transport: ["webSockets", "longPolling"] })
        .done(function () {
          // Connessione effettuata
          console.log("MEETING Hub Connection Done!");
          resolve();
        })
        .fail(function (e: any) {
          console.error(`tryConnectSignalR fail ${e}`);

          currentRetry++;
          if (currentRetry < connectionRetry) {
            // Retry
            setTimeout(function () {
              connectMeetingSignalR().then(() => {
                resolve();
              });
            }, retryTimeout);
          } else {
            console.error(
              `Cannot connect to the meeting hub server at this time. Please try login again.`
            );
            reject();
          }
        });
    } catch (e) {
      console.error(`tryConnectSignalR MEETING error ${e}`);

      currentRetry++;
      if (currentRetry < connectionRetry)
        setTimeout(function () {
          connectMeetingSignalR().then(() => {
            resolve();
          });
        }, retryTimeout);
      else {
        console.error(
          `Cannot connect to the MEETINGserver at this time. Please try login again.`
        );
        reject();
      }
    }
  });
}

async function getMessages({
  roomId = "",
  messageId = null,
  length = 50,
  types = [500],
}) {
  const resp = await api("chat").get(
    `api/Groups/GetMessages?startMessageId=${messageId}&roomId=${roomId}&length=${length}&messageTypes=${types.join(
      ","
    )}`
  );
  return resp.data.Messages.map((rawMsg: any) => {
    return ChatMessageDeserializer(rawMsg);
  });
}

async function receive(args: any) {
  return args;
}

function send(args: any, state: any) {
  const connectionState = state().chat.chatHubConnectionState;
  if (connectionState === 1 && chatHubProxy) {
    return sendMessage(args);
  } else {
    return new Promise((resolve, reject) => {
      reject();
    });
  }
}

function setWebConferenceStatus(args: any, state: any) {
  const connectionState = state().chat.meetingHubConnectionState;
  if (connectionState === 1 && meetingHubProxy) {
    return new Promise((resolve, reject) => {
      meetingHubProxy
        .invoke("SetWebConferenceStatus", args.status)
        .done((resp: any) => {
          resolve({ resp, args });
        })
        .fail(function (e: any) {
          console.error(`message send fail  ${e}`);
          reject();
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      reject();
    });
  }
}

function sendMessage(args: any) {
  switch (args.type) {
    case "Message":
      return new Promise((resolve, reject) => {
        chatHubProxy
          .invoke(
            "SendMessage",
            args.type,
            args.message,
            null,
            args.groupId,
            args.isGroup
          )
          .done((resp: any) => {
            resolve({ resp, args });
          })
          .fail(function (e: any) {
            console.error(`message send fail  ${e}`);
            reject();
          });
      });
    case "OpenWebConference":
      return new Promise((resolve, reject) => {
        chatHubProxy
          .invoke("OpenWebConference", args.groupId, true)
          .done((resp: any) => {
            resolve({ resp, args });
          })
          .fail(function (e: any) {
            console.error(`message send fail  ${e}`);
            reject(e);
          });
      });
    case "CloseWebConference":
      return new Promise((resolve, reject) => {
        chatHubProxy
          .invoke("CloseWebConference", args.groupId, true)
          .done((resp: any) => {
            resolve({ resp, args });
          })
          .fail(function (e: any) {
            console.error(`message send fail  ${e}`);
            reject(e);
          });
      });
    // case "OpenRoom":
    //     return new Promise((resolve, reject) => {
    //         resolve({}, args);
    //     })
    // case "CloseRoom":
    //     return new Promise((resolve, reject) => {
    //         resolve({}, args);
    //     })
    default:
      return new Promise((resolve, reject) => {
        reject();
      });
  }
}
