import {License} from "../modules/license/interfaces";

export interface User {
  availableStorageQuota?: number;
  avatarDate?: Date;
  city?: string;
  docuSignUserInfo?: any; //TODO no info about this field
  email?: string;
  firstName: string;
  isAdmin?: boolean; // TODO check this field
  lastName: string;
  roles?: string[];
  settingsInfo?: {
    magoCastServerUrl?: string;
    webAppServerUrl: string;
    webConferenceJoinUrl: string;
  };
  license?: License;
  storageQuota?: number;
  subscription?: UserSubscription;
  updateDate?: Date;
  userId: string;
  userName?: string;
  visibleForSearch: boolean;
}

export function UserDeserializer(input: any): User {
  const subscription = input.Subscription ? UserSubscriptionDeserializer(input.Subscription): undefined
  return {
    availableStorageQuota: input.AvailableStorageQuota,
    avatarDate: input.AvatarDate,
    city: input.City || "",
    docuSignUserInfo: input.DocuSignUserInfo,
    email: input.Email,
    firstName: input.FirstName,
    isAdmin: input.IsAdmin,
    lastName: input.LastName,
    roles: input.Roles,
    settingsInfo: {
      magoCastServerUrl: input.MagoCastServerUrl,
      webAppServerUrl: input.WebAppServerUrl,
      webConferenceJoinUrl: input.WebConferenceJoinUrl,
    },
    license: input.License,
    storageQuota: input.StorageQuota,
    subscription: subscription,
    updateDate: input.UpdateDate,
    userId: input.UserId,
    userName: input.UserName,
    visibleForSearch: input.VisibleForSearch,
  };
}

export interface Company {
  companyId?: string;
  companyName?: string;
  companyLegalName?: string;
  locality?: string;
  region?: string;
  postalCode?: string;
  street?: string;
  telephone?: string;
}

export function CompanyDeserializer(input: any): Company {
  return {
    companyId: input[0].CompanyId,
    companyName: input[0].CompanyName,
    companyLegalName: input[0].CompanyLegalName || "",
    locality: input[0].Locality,
    region: input[0].Region,
    postalCode: input[0].PostalCode,
    street: input[0].Street,
    telephone: input[0].Telephone,
  };
}

export interface UserSubscription {
  bingSearchDailyQuota?: number;
  bingSearchEnabled: boolean;
  description: string;
  digitalSignatureEnabled: boolean;
  globalChatEnabled: boolean;
  globalChatFileShareEnabled: boolean;
  globalChatMaxFileLength?: number;
  globalChatMaxUserToGroup?: number;
  globalChatStyleEnabled: boolean;
  guestEnabled: boolean;
  magicToolsEnabled: boolean;
  magocastEnabled: boolean;
  magocastQuota?: number;
  meetingChatEnabled: boolean;
  meetingFileUploadQuota?: number;
  meetingMaxConcurrentUsers?: number;
  meetingMaxWriterUser?: number;
  meetingsMaxItems?: number;
  meetingsStorageQuota?: number;
  myScriptEnabled: boolean;
  podEnabled: boolean;
  recordingEnabled: boolean;
  subscriptionId: string;
  webConferenceEnabled: boolean;
  webinarEnabled: boolean;
  webinarMaxUsers?: number;
}

export function UserSubscriptionDeserializer(input: any): UserSubscription {
  return {
    bingSearchDailyQuota: input.BingSearchDailyQuota,
    bingSearchEnabled: input.BingSearchEnabled,
    description: input.Description || "",
    digitalSignatureEnabled: input.DigitalSignatureEnabled,
    globalChatEnabled: input.GlobalChatEnabled,
    globalChatFileShareEnabled: input.GlobalChatFileShareEnabled,
    globalChatMaxFileLength: input.GlobalChatMaxFileLength,
    globalChatMaxUserToGroup: input.GlobalChatMaxUserToGroup,
    globalChatStyleEnabled: input.GlobalChatStyleEnabled,
    guestEnabled: input.GuestEnabled,
    magicToolsEnabled: input.MagicToolsEnabled,
    magocastEnabled: input.MagocastEnabled,
    magocastQuota: input.MagocastQuota,
    meetingChatEnabled: input.MeetingChatEnabled,
    meetingFileUploadQuota: input.MeetingFileUploadQuota,
    meetingMaxConcurrentUsers: input.MeetingMaxConcurrentUsers,
    meetingMaxWriterUser: input.MeetingMaxWriterUser,
    meetingsMaxItems: input.MeetingsMaxItems,
    meetingsStorageQuota: input.MeetingsStorageQuota,
    myScriptEnabled: input.MyScriptEnabled,
    podEnabled: input.PodEnabled,
    recordingEnabled: input.RecordingEnabled,
    subscriptionId: input.SubscriptionId || "",
    webConferenceEnabled: input.WebConferenceEnabled,
    webinarEnabled: input.WebinarEnabled,
    webinarMaxUsers: input.WebinarMaxUsers,
  };
}
