import { User, UserDeserializer } from "models/user.interface";

export interface ChatMessage {
  creationDate?: Date;
  destinationId: string;
  file?: any; // TODO missing info
  isDeleted?: boolean;
  isGroup?: boolean;
  isReadInOtherDevice?: boolean;
  message: string;
  messageId: string;
  messageType: messageTypes;
  user: User;
}

enum messageTypesDecoder {
  GroupCreateOrUpdated = 100,
  ChatMeetingUpdated = 105,
  OpenWebConference = 110,
  OpenRoom = 120,
  CloseWebConference = 130,
  CloseRoom = 140,
  CustomNotification = 150,
  AddUserToGroup = 200,
  RemoveUserFromGroup = 300,
  UserLeaveGroup = 400,
  Message = 500,
  Error = 700,
  Info = 800,
}

export function ChatMessageDeserializer(input: any): ChatMessage {
  const user = UserDeserializer(input.User);
  return {
    creationDate: input.CreationDate,
    destinationId: input.DestinationId || "",
    file: input.File,
    isDeleted: input.IsDeleted,
    isGroup: input.IsGroup,
    isReadInOtherDevice: input.IsReadInOtherDevice,
    message: input.Message || "",
    messageId: input.MessageId || "",
    messageType: messageTypesDecoder[input.MessageType] as messageTypes,
    user: user,
  };
}

type messageTypes =
  | "GroupCreateOrUpdated"
  | "ChatMeetingUpdated"
  | "OpenWebConference"
  | "OpenRoom"
  | "CloseWebConference"
  | "CloseRoom"
  | "CustomNotification"
  | "AddUserToGroup"
  | "RemoveUserFromGroup"
  | "UserLeaveGroup"
  | "Message"
  | "Error"
  | "Info";
