import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { roomService } from "../services";
import { Room } from "models/room.interface";
import { RoomInfo } from "../interfaces";
import { User } from "models/user.interface";

const initialState = {
  rooms: [] as Room[],
  singleRooms: [],
  roomsLastFetch: 0,
  activeRoom: {} as Room,
  activeRoomInfo: {} as RoomInfo,
  lights: new Date().getHours() > 6 && new Date().getHours() < 22,
  status: "idle",
  getRoomStatus: "idle",
  getRoomInfoStatus: "idle",
  connectGuestStatus: "idle",
  removeUserStatus: "idle",
  addUserStatus: "idle",
  searchUsersStatus: "idle",
  leaveRoomStatus: "idle",
  editRoomStatus: "idle",
  error: null,
  userSearchResults: [] as User[],
};

export const getRooms = createAsyncThunk("room/getRooms", async () => {
  return await roomService.getRooms();
});

export const getRoom = createAsyncThunk("room/getRoom", async (id: string) => {
  return await roomService.getRoom(id);
});

export const getSingleRoom = createAsyncThunk(
  "room/getSingleRoom",
  async (id: string) => {
    return await roomService.getSingleRoom(id);
  }
);

export const getRoomInfo = createAsyncThunk(
  "room/getRoomInfo",
  async (values: { id: string; isGroup: boolean }) => {
    return await roomService.getRoomInfo(values.id, values.isGroup);
  }
);

export const removeUser = createAsyncThunk(
  "room/removeUser",
  async (values: { groupId: string; userId: string }) => {
    return await roomService.removeUser(values.groupId, values.userId);
  }
);

export const leaveRoom = createAsyncThunk(
  "room/leaveRoom",
  async (values: { room: Room }) => {
    let response = await roomService.leaveRoom(values.room.groupId);
    return { response: response, room: values.room };
  }
);

export const editRoom = createAsyncThunk(
  "room/editRoom",
  async (values: { groupId: string; name: string; description: string }) => {
    const result = await roomService.editRoom(
      values.groupId,
      values.name,
      values.description
    );
    return result;
  }
);

export const addUser = createAsyncThunk(
  "room/addUser",
  async (values: { groupId: string; user: User }) => {
    return await roomService.addUser(values.groupId, values.user);
  }
);

export const searchUsers = createAsyncThunk(
  "room/searchUsers",
  async (values: { filter: string }) => {
    return await roomService.searchUsers(values.filter);
  }
);

const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    setLights(state, action) {
      state.lights = action.payload;
    },
    resetActiveRoom(state) {
      state.activeRoom = {} as Room;
    },
  },
  extraReducers: (builder) => {
    //GET ROOMS
    builder.addCase(getRooms.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getRooms.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.rooms = action.payload;
      state.roomsLastFetch = +new Date();
    });
    builder.addCase(getRooms.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as any;
    });
    //GET ROOM
    builder.addCase(getRoom.pending, (state, action) => {
      state.getRoomStatus = "loading";
    });
    builder.addCase(getRoom.fulfilled, (state, action) => {
      state.getRoomStatus = "succeeded";
      state.activeRoom = action.payload;
    });
    builder.addCase(getRoom.rejected, (state, action) => {
      state.getRoomStatus = "failed";
      state.error = action.payload as any;
    });
    //GET SINGLE ROOM
    builder.addCase(getSingleRoom.pending, (state, action) => {
      state.getRoomStatus = "loading";
    });
    builder.addCase(getSingleRoom.fulfilled, (state, action) => {
      state.getRoomStatus = "succeeded";
      state.activeRoom = action.payload;
    });
    builder.addCase(getSingleRoom.rejected, (state, action) => {
      state.getRoomStatus = "failed";
      state.error = action.payload as any;
    });
    //GET ROOM INFO
    builder.addCase(getRoomInfo.pending, (state, action) => {
      state.getRoomInfoStatus = "loading";
    });
    builder.addCase(getRoomInfo.fulfilled, (state, action) => {
      state.getRoomInfoStatus = "succeeded";
      state.activeRoomInfo = action.payload;
    });
    builder.addCase(getRoomInfo.rejected, (state, action) => {
      state.getRoomInfoStatus = "failed";
      state.error = action.payload as any;
    });
    //REMOVE USER
    builder.addCase(removeUser.pending, (state, action) => {
      state.removeUserStatus = "loading";
    });
    builder.addCase(removeUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.removeUserStatus = "succeeded";
        const newusers = state.activeRoom.users?.filter((user: User) => {
          return user.userId !== action.meta.arg.userId;
        });
        state.activeRoom.users = newusers;
      } else {
        state.removeUserStatus = "failed";
      }
    });
    builder.addCase(removeUser.rejected, (state, action) => {
      state.removeUserStatus = "failed";
      state.error = action.payload as any;
    });
    //ADD USER
    builder.addCase(addUser.pending, (state, action) => {
      state.addUserStatus = "loading";
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.addUserStatus = "succeeded";
        state.activeRoom.users?.push(action.meta.arg.user);
      } else {
        state.addUserStatus = "failed";
      }
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.addUserStatus = "failed";
      state.error = action.payload as any;
    });
    //SEARCH USERS
    builder.addCase(searchUsers.pending, (state, action) => {
      state.searchUsersStatus = "loading";
    });
    builder.addCase(searchUsers.fulfilled, (state, action) => {
      state.searchUsersStatus = "succeeded";
      state.userSearchResults = action.payload;
    });
    builder.addCase(searchUsers.rejected, (state, action) => {
      state.searchUsersStatus = "failed";
      state.error = action.payload as any;
    });
    //LEAVE ROOM
    builder.addCase(leaveRoom.pending, (state, action) => {
      state.leaveRoomStatus = "loading";
    });
    builder.addCase(leaveRoom.fulfilled, (state, action) => {
      if (action.payload.response) {
        state.leaveRoomStatus = "succeeded";
        state.rooms = state.rooms.filter((room: Room) => {
          return room.groupId !== action.payload.room.groupId;
        });
      } else {
        state.leaveRoomStatus = "failed";
      }
    });
    builder.addCase(leaveRoom.rejected, (state, action) => {
      state.leaveRoomStatus = "failed";
      state.error = action.payload as any;
    });
    //EDIT ROOM
    builder.addCase(editRoom.pending, (state, action) => {
      state.editRoomStatus = "loading";
    });
    builder.addCase(editRoom.fulfilled, (state, action) => {
      state.editRoomStatus = "succeeded";
      state.rooms = state.rooms.map((room: Room) => {
        if(room.groupId === action.payload.groupId){
          room.name = action.payload.name;
          room.description = action.payload.description;
          return room;
        } else{
          return room;
        }
      });
      state.activeRoom.name = action.payload.name;
      state.activeRoom.description = action.payload.description;
    });
    builder.addCase(editRoom.rejected, (state, action) => {
      state.editRoomStatus = "failed";
      state.error = action.payload as any;
    });
  },
});

export const { setLights, resetActiveRoom } = roomSlice.actions;

export default roomSlice.reducer;
