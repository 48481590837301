import api from "helpers/apiService";
import {User, UserDeserializer, CompanyDeserializer, Company} from "models/user.interface";

export const userService = {
  getUserMe,
  getCompany,
  findUsers,
};

async function getUserMe(state: any): Promise<User> {
  const resp = await api().get(`api/License/User/Me`);
  const res = UserDeserializer(resp.data);
  return res;
}

async function getCompany(): Promise<Company> {
  const resp = await api().get(`api/License/Company`);
  const res = CompanyDeserializer(resp.data);
  return res;
}

async function findUsers(values: {
  query: string;
  max: number;
}): Promise<User[]> {
  const resp = await api("chat").get(
    `api/GlobalChat/FindUsers?filter=${values.query}&maxUsers=${
      values.max || 100
    }`
  );
  return resp.data.map((rawUser: any) => {
    return UserDeserializer(rawUser);
  });
}
