import axios from "axios";
// import { AxiosAdapter } from "axios";

import { message } from "antd";
// import { cacheAdapterEnhancer } from "axios-extensions";

export default (baseUrl?: string) => {
  let base;
  switch (baseUrl) {
    case undefined:
      base = process.env.REACT_APP_RMS_API_BASEURL;
      break;
    case "chat":
      base = localStorage.getItem("chat-server") || '' ;
      if (!base) {
        throw new Error(
          `Unhandled base url request for: ${baseUrl}, no localstorage item for "chat-server"`
        );
      }
      break;
    case "meeting":
      base = localStorage.getItem("meeting-server") || '' ;
      if (!base) {
        throw new Error(
          `Unhandled base url request for: ${baseUrl}, no localstorage item for "meeting-server"`
        );
      }
      break;
    default:
      throw new Error(`Unhandled base url request for: ${baseUrl}`);
  }

  const ax = axios.create({
    baseURL: base,
    //   adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter),
  });

  ax.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem("x-auth-token");
      if (token && config.url !== "api/auth" && config.url !== "api/Meeting/GetMeeting") {// TODO: BAD! refactor me
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      console.error(error);
      return Promise.reject(error);
    }
  );

  ax.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const errorMessage =
        error?.response?.data?.error_description ||
        error?.response?.error_description ||
        error?.response?.statusText;
      if (error?.response?.status === 401) {
        //message.info("Not authorized or bad request. Please try again.");
      } else {
        message.error(errorMessage);
      }

      return Promise.reject(errorMessage);
    }
  );
  return ax;
};
